.create-tickets-page {
  .invite-search-bar {
    margin-bottom: 20px;
  }

  .tickets-overview {
    margin: 22.5px 0 0 0;
  }

  .form-wrapper {
    margin-top: 20px;
    position: relative;
  }

  .report-section-header {
    font-size: 18px;
  }
}
